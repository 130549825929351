export const FETCH_OPTIONS = 'FETCH_OPTIONS';
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_SETUP = 'FETCH_SETUP';

export const FETCH_MEASURES = 'FETCH_MEASURES';
export const FETCH_SELECTED_MEASURE = 'FETCH_SELECTED_MEASURE';
export const CREATE_MEASURE = 'CREATE_MEASURE';
export const UPDATE_MEASURE = 'UPDATE_MEASURE';
export const DELETE_MEASURE = 'DELETE_MEASURE';

export const FETCH_SELECTED_ACTIVITY = 'FETCH_SELECTED_ACTIVITY';
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_AUTH_INFO = 'UPDATE_AUTH_INFO';

export const UPDATE_SELECTED_LANGUAGE = 'UPDATE_SELECTED_LANGUAGE';